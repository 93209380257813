import { RoundSocials } from "../Components";

export const Home = () => {
  const renderh = () => {
    let r = [];
    const h = ["design", "code", "do"];
    const steps = 1 / (h.length + 1) * 0.7;
    let op = 0.6 - steps;
    r.push(<h2 key={0} style={{ opacity: 1 }}>I develop things</h2>);
    let idx = 1;
    h.forEach((he) => {
      r.push(<h2 key={idx} style={{ opacity: op }}>{he} <span style={{opacity: "0.6"}}>things</span></h2>);
      op -= steps;
      idx++;
    })

    return r;
  }
  
  return <div className="centered">
      <div className="text">
      <h1>Hi, I'm Louis.</h1>
      { renderh() }
      </div> 
      <div className="far">
        <RoundSocials />
      </div>
  </div>;
}