import { useEffect, useRef, useState, useContext } from 'react';
import { Box, Home as IconHome, Code, ChevronsRight, Camera, Smile, Info, Mail, Edit2, Link, ChevronDown, Maximize2, X, Menu, Cpu, GitHub, Instagram, Linkedin, Mic, Settings } from "react-feather";

import { NavLink, useLocation } from "react-router-dom";
import { MyContext } from './MyContext';

const desktopWidth = 1200;

export const WinWindow = (props) => {
  const [minimized, setMinimized] = useState(props.Minimized || false);

  return <div className="winWindow" style={{...props.style}}>
    <div onClick={(e) => {e.preventDefault(); setMinimized(!minimized); }} style={{ cursor: "pointer" }}><b style={{ minWidth: "70%", textAlign: "left", display: "inline-block", left: "0.4em", position: "absolute"}}>{ props.Title }</b><ChevronDown /><Maximize2 /><X /></div>
    <div className="content" style={{display: minimized? "none": "block"}}>
      { props.children }
    </div>
  </div>
  }

export const Nav = () => {
  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
      if (window.innerWidth > desktopWidth)
          setShowNav(true);
  }, [])

  const hideNavOnMobile = () => {
    if (window.innerWidth <= desktopWidth) {
      setShowNav(false);
    }
  }
  
  return <>
  <div className='burger'>
    <a href="./" onClick={(e) => { e.preventDefault(); setShowNav(!showNav); }} alt="Toggle Navigation menu">{ showNav? <X />: <Menu /> }</a>
  </div>
  <nav className={ showNav ? null : "hidden" }>
    <ul>
      <li><NavLink end to="./" onClick={ hideNavOnMobile }><IconHome /> Home</NavLink></li>
      <li><NavLink end to="./software" onClick={ hideNavOnMobile }><Code /> Software</NavLink>
        <ul>
          <li><NavLink end to="./software/front-end" onClick={ hideNavOnMobile }><ChevronsRight /> Front End</NavLink></li>
          <li><NavLink end to="./software/back-end" onClick={ hideNavOnMobile }><ChevronsRight /> Back End</NavLink></li>
        </ul>
      </li>
      <li><NavLink end to="./design" onClick={ hideNavOnMobile }><Edit2 /> Design</NavLink>
        <ul>
          <li><NavLink end to="./design/3d" onClick={ hideNavOnMobile }><Box /> 3D Objects</NavLink></li>
          <li><NavLink end to="./design/photography" onClick={ hideNavOnMobile }><Camera /> Photography</NavLink></li>
        </ul>
      </li>
      <li><NavLink end to="./about" onClick={ hideNavOnMobile }><Smile /> About Me</NavLink>
        <ul>
          <li><NavLink end to="./about/resume" onClick={ hideNavOnMobile }><Info /> Resumé / CV</NavLink></li>
          <li><NavLink end to="./about/contact" onClick={ hideNavOnMobile }><Mail /> Contact</NavLink></li>
          <li><NavLink end to="./about/socials" onClick={ hideNavOnMobile }><Link /> Socials</NavLink></li>
        </ul>
      </li>
    </ul>
  </nav>
  </>
}

export const Rating = (props) => {
  const rating = props.Rating || 5;
  return (
    <div className="rating">
      {[...Array(5)].map((_, index) => {
        return (
          <span
            key={index}
            className={index <= rating - 1 ? "on" : "off"}
          >
            <Cpu />
          </span>
        );
      })}
    </div>
  );
}

export const ButtonLink = (link, name, obj) => {
  return (
  <a href={ link } alt={ name } className="socialLink" target="_blank" rel="noreferrer">
  { obj }
  </a>
  )
}

export const RoundSocials = (props) => {
  return <center>
    { ButtonLink("https://github.com/louis-dresbach", "Github", <GitHub />) }
    { ButtonLink("https://www.instagram.com/mynameisnotlouis/", "Instagram", <Instagram />) }
    { ButtonLink("https://www.linkedin.com/in/louis-dresbach", "Linkedin", <Linkedin />) }
    { ButtonLink("mailto:louis@louis-dresbach.de", "E-Mail", <Mail />) }
    { ButtonLink("#", "Discord", <Mic />) }
  </center>
}

export const Rain = (props) => {
  const canvasRef = useRef(null);
  const maxParts = 1500;
  const frameRate = 50;
  const [mouseAngle, setMouseAngle] = useState(0);
  const [parts, setParts] = useState([]);
  const [frame, setFrame] = useState(0);
  const { enableLighting } = useContext(MyContext);

  const [lightning, setLightning] = useState(null);
  const [particles, setParticles] = useState([]);

  const mouseMove = (e) => {
    const w = window.innerWidth;
    let a; 
    e.clientY === 0 ? a = 0 : a = Math.atan((e.clientX - (w / 2)) / e.clientY);
    setMouseAngle(a / 4);
  }

  useEffect(() => {
    const canvas = canvasRef.current;

    var tmp = [];
    for (let i=0; i < maxParts; i++) {
      tmp.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        length: Math.random() * 20,
        angle: (Math.random() / 7),
        opacity: Math.random() * 0.1
      })
    }
    setParts(tmp);
    document.addEventListener('mousemove', mouseMove);

    return () => {
      document.removeEventListener('mousemove', mouseMove);
    }
  }, []);

  const [t, setT] = useState(null);
  useEffect(() => {
    const canvas = canvasRef.current;
    const c = canvas.getContext('2d');

    let now = Date.now(),
    elapsed = now - t;

    if (elapsed > (1000 / frameRate) || t === null) {
      setT(now);

      c.clearRect(0, 0, canvas.width, canvas.height);
      c.lineCap = "round";
      for(var p of parts) {
        let op = p.opacity;
        if (enableLighting && lightning !== null) {
          // if raindrop is close to lightning path, make it bright
          const dist = Math.abs(p.x - lightning.x);
          op = Math.min(1, Math.max(op, 1 - (dist / canvas.width * 2)));
        }
        c.strokeStyle = "rgba(171, 238, 224, " + op + ")";
        c.beginPath();
        c.moveTo(p.x, p.y);
        c.lineTo(p.x + p.length * Math.sin(mouseAngle + p.angle), p.y + p.length * Math.cos(mouseAngle + p.angle));
        c.stroke();
  
        p.x += p.length * Math.sin(mouseAngle + p.angle);
        p.y += p.length * Math.cos(mouseAngle + p.angle);
        if(p.x >= canvas.width || p.x <= 0 || p.y >= canvas.height) {
          p.x = Math.random() * canvas.width;
          p.y = -10;
        }
      }
  
      if (enableLighting) {
        if (lightning === null) { // max of 5 lightnings total
          const r = Math.random();
          if (r < 0.01) {
            setLightning({
              x: Math.random() * canvas.width,
              y: -10,
              length: Math.max(100, Math.random() * 200),
              angle: (Math.random() / 20)});
          }
        }
        else {
          const p = lightning;
          c.strokeStyle = "#f1f8ff";
          c.beginPath();
          c.moveTo(p.x, p.y);
          const dx = p.length * Math.sin(p.angle);
          const dy = p.length * Math.cos(p.angle);
          c.lineTo(p.x + dx, p.y + dy);
          c.stroke();
  
          p.x += dx;
          p.y += dy;
          if(p.x >= canvas.width || p.x <= 0 || p.y >= canvas.height) {
            // small explosion
            let tmp = [];
            for (let i = 0; i < 10; i++) {
              tmp.push({
                x: p.x,
                y: canvas.height,
                angle: Math.random() * Math.PI,
                lives: 100
              })
            }
            setParticles(tmp);
            setLightning(null);
          }
        }
      }
  
      for (var pa of particles) {
        if (pa.lives > 0) {
          let op = pa.lives / 100;
          c.strokeStyle = "rgba(250, 241, 236, " + op + ");";
          c.beginPath();
          c.moveTo(pa.x, pa.y);
          const dx = - 2 * Math.sin(pa.angle);
          const dy = - 2 * Math.cos(pa.angle);
          c.lineTo(pa.x + dx, pa.y + dy);
          c.stroke();
  
          pa.x += dx;
          pa.y += dy;
  
          pa.lives--;
        }
      }
    }
      
  
    const req = requestAnimationFrame(() => { setFrame(frame + 1) });
  
    return () => {
      window.cancelAnimationFrame(req);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frame])

  return <canvas className="background" width="1280px" height="720px" ref={ canvasRef } />
}

export const SoapBubbles = (props) => {
  const canvasRef = useRef(null);
  const maxParts = 200;
  const frameRate = 50;
  const [mouseAngle, setMouseAngle] = useState(0);
  const [parts, setParts] = useState([]);
  const [frame, setFrame] = useState(0);

  const mouseMove = (e) => {
    const w = window.innerWidth;
    let a; 
    e.clientY === 0 ? a = 0 : a = Math.atan((e.clientX - (w / 2)) / e.clientY);
    setMouseAngle(a / 4);
  }

  useEffect(() => {
    const canvas = canvasRef.current;

    var tmp = [];
    for (let i=0; i < maxParts; i++) {
      tmp.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        length: Math.max(5, Math.random() * 30),
        angle: (Math.random() / 7),
        opacity: Math.random() * 0.3
      })
    }
    setParts(tmp);
    document.addEventListener('mousemove', mouseMove);

    return () => {
      document.removeEventListener('mousemove', mouseMove);
    }
  }, []);

  const [t, setT] = useState(null);
  useEffect(() => {
    const canvas = canvasRef.current;
    const c = canvas.getContext('2d');

    let now = Date.now(),
    elapsed = now - t;

    if (elapsed > (1000 / frameRate) || t === null) {
      setT(now);

      c.clearRect(0, 0, canvas.width, canvas.height);
      c.lineCap = "round";
      for(var p of parts) {
        c.strokeStyle = "rgba(58, 85, 189, " + p.opacity + ")";

        c.moveTo(p.x, p.y);
        c.beginPath();
        c.arc(p.x, p.y, p.length, 0, 2 * Math.PI);
        c.stroke();

        c.beginPath();
        c.arc(p.x, p.y, p.length * 0.7, p.angle + mouseAngle + 0.5 * Math.PI, p.angle + mouseAngle + 1 * Math.PI);
        c.stroke();
        c.beginPath();
        c.arc(p.x, p.y, p.length * 0.65, p.angle + mouseAngle + 1.45 * Math.PI, p.angle + mouseAngle + 1.7 * Math.PI);
        c.stroke();
  
        p.x += 0.3 * p.length * Math.sin(mouseAngle + p.angle);
        p.y += 0.3 * p.length * Math.cos(mouseAngle + p.angle);
        if(p.x >= canvas.width || p.x <= 0 || p.y >= canvas.height) {
          p.x = Math.random() * canvas.width;
          p.y = -10;
        }
      }
    }
      
    const req = requestAnimationFrame(() => { setFrame(frame + 1) });
  
    return () => {
      window.cancelAnimationFrame(req);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frame])

  return <canvas className="background" width="1280px" height="720px" ref={ canvasRef } />
}

export const FloatingFooter = (props) => {
  const { 
    lightTheme, 
    toggleTheme,
    enableRain,
    toggleRain,
    enableBubbles,
    toggleBubbles,
    enableLighting,
    toggleLighting,
    toggle3D } = useContext(MyContext);

  const [w, setW] = useState(window.innerWidth);

  const resize = () => {
    setW(window.innerWidth);
  }

  useEffect(() => {
    document.addEventListener("resize", resize);

    return () => {
      document.removeEventListener('resize', resize);
    }
  },  [])

  const lSet = <>
  Soap Bubbles: <input type="checkbox" className="toggle" checked={ enableBubbles } onChange={ toggleBubbles } />
  </>

  const dSet = <>
  Rain: <input type="checkbox" className="toggle" checked={ enableRain } onChange={ toggleRain } />
  Lighting: <input type="checkbox" className="toggle" checked={ enableRain && enableLighting } onChange={ toggleLighting } disabled={ !enableRain }/>
  </>

  return(<footer>
  <Settings className='middle'/>&nbsp;
  Toggle light: <input type="checkbox" className="toggle" checked={ lightTheme } onChange={ toggleTheme } />
  <div className="vr" /> {
    lightTheme ? 
      lSet : 
      dSet
  } 
  <div style={{ display: (w > desktopWidth) ? "inline-block": "block" }}>This site is available in 3D / VR. <button onClick={ () => { toggle3D() } }>Click here to try it out.</button></div>
  </footer>)
}

export const ScrollToTop = (props) => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash !== null && hash !== "") {
      const el = document.getElementById(hash.substring(1));
      if (el !== null) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    }
    else {
      const m = document.getElementsByClassName("main");
      if (m.length === 1) {
        m[0].scroll({top: 0, behavior: 'smooth'});
      }
    }
  }, [pathname, hash]);

  return props.children
}