import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useState, useContext } from "react";
import { GitHub, Linkedin, Instagram, File, Mail, Mic } from 'react-feather';
import { RoundSocials } from '../Components';
import { MyContext } from '../MyContext';

export const Contact = (props) => {
    return <div className={ props.Included === true ? "" : "normal" } >
        <h1>Contact Me</h1>
        <p>You can contact me using the form below or through any of the listed social media channels.</p>
        <form action="">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" placeholder="Bob Builder" disabled />

            <label htmlFor="email">Email</label>
            <input type="text" id="email" name="email" placeholder="example@mail.com" disabled />

            <label htmlFor="subject">Subject</label>
            <textarea id="subject" name="subject" placeholder="Write something.." style={{ height:"10em" }} disabled />

            <input type="submit" value="Submit" disabled />
        </form>
        <small>I have decided to disable the contact form because of spam. Please use <a href="socials">another channel</a> to contact me.</small>
        <br />
        <div style={{ padding: "1em" }}>
            <RoundSocials />
        </div>
    </div>
}
export const Resume = (props) => {
    const { lightTheme } = useContext(MyContext);

    const [numPages, setNumPages] = useState(null);
    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    }

    const file = lightTheme ? "/images/resume_dark.pdf" : "/images/resume_dark.pdf";

    return <div className={ props.Included === true ? "" : "normal" } >
        <h1>Resumé</h1>
        <Document file={ file } onLoadSuccess={ onDocumentLoadSuccess } loading={<><h3>Loading</h3><p>The file is loading, please wait.</p></>}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} renderMode="svg" />
            ))}
        </Document>
        <p>Alternatively, you can download and view it <a target="_blank" rel="noreferrer" href={ file }>as a PDF here</a></p>
    </div>
}
export const Socials = (props) => {
    return <div className={ props.Included === true ? "" : "normal" } >
        <h1>Social Media</h1>
        <p>You can find me on these platforms:</p>
        <ul>
            <li><a target="_blank" rel="noreferrer" href="https://github.com/louis-dresbach" alt="GitHub"><GitHub /> GitHub</a></li>
            <li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/mynameisnotlouis/" alt="Instagram"><Instagram /> Instagram</a></li>
            <li><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/louis-dresbach" alt="LinkedIn"><Linkedin /> LinkedIn</a></li>
            <li><a target="_blank" rel="noreferrer" href="mailto:louis@louis-dresbach.de" alt="E-Mail"><Mail /> E-Mail</a></li>
            <li><a target="_blank" rel="noreferrer" href="https://platform.entwicklerheld.de/publicprofile/ac4c87ea2f49a19b712315bc65e655b3" alt="Entwicklerheld"><File /> Entwicklerheld</a></li>
            <li><a target="_blank" rel="noreferrer" href="https://www.get-in-it.de/profil/mTHl5vlNLjR2hTNqn0CEq6xwttXljk9J" alt="Get In IT"><File /> Get in IT</a></li>
            <li><a target="_blank" rel="noreferrer" href="https://discord.com/users/341551465627910145" alt="Discord"><Mic /> Discord</a></li>
        </ul>
    </div>
}
export const About = (props) => {
    return <div className={ props.Included === true ? "" : "normal" } >
        <h1>About Me</h1>
        <p><a href="https://louis-dresbach.de">Louis Dresbach</a> was born in <a href="https://en.wikipedia.org/wiki/Kobe">Kobe, Japan</a> in 1996. After a childhood spent mostly between his homes in Germany and Japan, he found his love for computers and programming during highschool.  </p>
        <p>There, he started creating, modifying and extending small programs for a small online gaming-community he was part of (bots, game servers, game mods). For that, he also began designing and editing images using <a href="https://en.wikipedia.org/wiki/Adobe_Photoshop">Photoshop</a> and the <a href="https://www.adobe.com/de/creativecloud.html">Adobe Suite</a> in general.</p>
        <p>Later, he began studying at a German University to become an Aerospace Engineer, while still developing software and hardware in his free time.</p>
        <Resume Included />
        <Socials Included />
    </div>
}