import './App.css';
import { useState, useRef, useEffect } from 'react';
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { ErrorBoundary } from './ErrorBoundary';

import { NotFound } from './pages/404';
import { Home } from './pages/Home';
import { Coding, FrontEnd, BackEnd } from './pages/Software';
import { Design, Photography, ThreeD } from './pages/Design';
import { SoapBubbles, FloatingFooter, Nav, ScrollToTop } from './Components';
import { About, Contact, Resume, Socials } from './pages/About';
import { Rain } from './Components';
import { MyContext } from './MyContext';
import { ThreeDView } from './VR';

const App = () => {
  const [circlePosX, setCirclePosX] = useState("-30em");
  const [circlePosY, setCirclePosY] = useState("-30em");

  const circleRef = useRef(null);
  
  const [lightTheme, setLightTheme] = useState(false);
  
  const toggleTheme = () => {
    const newVal = !lightTheme;
    if (newVal === true)
      document.documentElement.classList.add("light");
    else
      document.documentElement.classList.remove("light");

    setLightTheme(newVal);
    localStorage.setItem("themePreference", newVal);
  }

  const [enableRain, setRain] = useState(true);
  const toggleRain = () => {
    setRain(!enableRain);
  }

  const [enableBubbles, setFlare] = useState(true);
  const toggleBubbles = () => {
    setFlare(!enableBubbles);
  }

  const [enableLighting, setLighting] = useState(true);
  const toggleLighting = () => {
    setLighting(!enableLighting);
  }

  const [enable3D, set3D] = useState(false);
  const toggle3D = () => {
    set3D(!enable3D);
  }

  const context = {
    lightTheme,
    toggleTheme,

    enableRain,
    toggleRain,

    enableBubbles,
    toggleBubbles,

    enableLighting,
    toggleLighting,

    enable3D,
    toggle3D
  };

  useEffect(() => {
    // if user has set theme preference on our site, load it
    const pref = localStorage.getItem("themePreference");
    let l = false;
    if (pref !== null) {
      l = pref === "true";
    }
    else {// try to use the browser setting
      const dark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      l = !dark;
    }

    if (l === true) {
      document.documentElement.classList.add("light");
    }

    let r = localStorage.getItem("rain");
    if (r !== null) {
      r = r === "true";
    }
    else {
      r = true; // enable rain by default
      localStorage.setItem("rain", r);
    }

    setLightTheme(l);
    setRain(r);
  }, [])

  const background = () => {
    if (context.lightTheme) {
      return <>{/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 941 941" className="blurred circle" ref={ circleRef } style={{ top: circlePosY, left: circlePosX }} >
      <g transform="translate(150 150)">
        <circle cx="320.5" cy="320.5" r="320.5" />
        <circle cx="320.5" cy="320.5" r="320" />
      </g>
    </svg>*/}
    { enableBubbles && <SoapBubbles /> }
    </>
    }
    else {
      if (enableRain) {
        return <Rain />
      }
      else return null;
    }
  }

  return (
  <ErrorBoundary>
    <Router>
      <MyContext.Provider value={ context } >
        { enable3D ? 
        <>
        <ThreeDView />
        </> : 
        <>
          <div onMouseMove={ (e) => {
            if (circleRef.current !== null) {
              setCirclePosX(e.clientX / window.innerWidth * 30 - circleRef.current.clientWidth/2);
              setCirclePosY(e.clientY / window.innerHeight * 30 - circleRef.current.clientHeight/2);
            }
            }}>
          { background() }
          <div className="main">
            <ScrollToTop>
              <Routes>
                <Route exact path="/" element={ <Home /> } />
                <Route path="/software" element={ <Coding /> } />
                <Route path="/software/front-end" element={ <FrontEnd /> } />
                <Route path="/software/back-end" element={ <BackEnd /> } />
                <Route path="/design" element={ <Design /> } />
                <Route path="/design/photography" element={ <Photography /> } />
                <Route path="/design/3d" element={ <ThreeD /> } />
                <Route path="/about" element={ <About /> } />
                <Route path="/about/resume" element={ <Resume /> } />
                <Route path="/about/contact" element={ <Contact /> } />
                <Route path="/about/socials" element={ <Socials /> } />
                <Route path="*" element={ <NotFound /> }/>
              </Routes>
            </ScrollToTop>
          </div>
          <Nav />
            <FloatingFooter />
          </div>
        </> }
      </MyContext.Provider>
    </Router>
  </ErrorBoundary>
  );
}

export default App;
