import React, { useContext, useEffect } from 'react';

import {
  Scene,
  Box,
  Cylinder,
  Plane,
  Sky,
  Camera,
  Cursor,
  Entity,
  Sphere,
  Torus,
  Text,
  Assets,
  AssetItem,
  Light,
  Circle,
  Mixin,
  ObjModel,
  GLTFModel
} from '@belivvr/aframe-react';
import { MyContext } from './MyContext';
//import { DynamicBody } from "aframe-physics-system";

const AFRAME = window.AFRAME;
const AMMO = window.AMMO;

const guardianSize = 8;

const guardianDepth = 0.05;
const guardianHeight = 0.2;
const guardianColor = "#a1d7de";

const wallHeight = 4;

const tableMaterial = { roughness: 1, color: "#3e1b08", metalness: 0 };
const objectMaterial = { roughness: 0.7, color: "#AAE", metalness: 0 };

const textColor = "#D9D9D9";
const headerScale = { x: 0.8, y: 0.8, z: 1};
const textScale = { x: 0.5, y: 0.5, z: 1};

const MyObject = (props) => {
    const size = props.size || 1;

    return(<Entity {...props}>
        <Torus 
        position={{ x: 0, y: (0.1 * size), z: 0}}
        rotation={{ x: 90, y: 0, z: 0 }}
        radius={ 0.3 * size } 
        radiusTubular={ 0.01 * size }
        color="#15587d"
        />
        <Torus 
        position={{ x: 0, y: 0, z: 0 }}
        rotation={{ x: 90, y: 0, z: 0 }}
        radius={ 0.3 * size  } 
        radiusTubular={ 0.01 * size  }
        color="#15587d"
        />
        <Sphere
        position={{ x: 0, y: (0.08 * size), z: 0 }}
        radius={ 0.24 * size } 
        color="#15587d"
        />
        <Cylinder
        position={{ x: 0, y: (0.05 * size), z: 0 }}
        radius={ 0.3 * size }
        height={ 0.1 * size }
        color="#688f94"
        />
        <Light 
        type="point" 
        color="#688f94" 
        intensity="0.4"
        decay="2"
        distance="2"
        position={{ x: 0, y: 0.35 * size, z: 0 }} 
        />
    </Entity>)
}

const RoundedPlane = (props) => {
    const radius = props.radius || 0.1;
    const width = props.width || 1;
    const height = props.height || 1;

    const w = width - (2 * radius);
    const h = height - (2 * radius);

    return(
        <Entity {...props}>
            <Plane // main plane
                material={{ side: "double" }}
                width={ w }
                height={ h }
            />
            <Plane // top piece
                material={{ side: "double" }}
                width={ w }
                height={ radius * 2 }
                position={{ x: 0, y: h / 2, z: 0 }}
            />
            <Plane // bottom piece
                material={{ side: "double" }}
                width={ w }
                height={ radius * 2 }
                position={{ x: 0, y: (-1) * h / 2, z: 0 }}
            />
            <Plane // left piece
                material={{ side: "double" }}
                width={ radius * 2 }
                height={ h }
                position={{ x: (-1) * w / 2, y: 0, z: 0 }}
            />
            <Plane // right piece
                material={{ side: "double" }}
                width={ radius * 2 }
                height={ h }
                position={{ x: w / 2, y: 0, z: 0 }}
            />
            <Circle // top left 
                material={{ side: "double" }}
                radius={ radius }
                position={{ x: w /2, y: h / 2, z: 0 }}
            />
            <Circle // top right 
                material={{ side: "double" }}
                radius={ radius }
                position={{ x: (-1) * w /2, y: h / 2, z: 0 }}
            />
            <Circle // bottom left 
                material={{ side: "double" }}
                radius={ radius }
                position={{ x: w /2, y: (-1) * h / 2, z: 0 }}
            />
            <Circle // bottom right 
                material={{ side: "double" }}
                radius={ radius }
                position={{ x: (-1) * w /2, y: (-1) * h / 2, z: 0 }}
            />
            { props.children }
        </Entity>)
}

const Background = (props) => {
    const panelMaterial = { roughness: 0.1, color: "#111", metalness: 0.9 };
    const panelSize = 0.6;
    const panelHeight = 0.2;
    const maxDist = 22;
    const minDist = 4;

    let panels = [];

    const dy = -0.6;
    
    const count = (maxDist / panelSize) + 1;
    for (var i=0; i <= count; i++) {
        for (var k=0; k < count; k++) {
            const dist = Math.sqrt(Math.pow(i, 2) + Math.pow(k, 2));

            if (dist < maxDist && dist > minDist) {
                panels.push({ 
                    position: { x: k * panelSize, y: panelHeight + dy, z: i * panelSize },
                    delay: 1000 * dist
                });
                if (i !== 0) {
                    panels.push({ 
                        position: { x: k * panelSize, y: panelHeight + dy, z: -i * panelSize },
                        delay: 1000 * dist
                    });
                }
                if (k !== 0) {
                    panels.push({ 
                        position: { x: -k * panelSize, y: panelHeight + dy, z: i * panelSize },
                        delay: 1000 * dist
                    });
                    if (i !== 0) {
                        panels.push({ 
                            position: { x: -k * panelSize, y: panelHeight + dy, z: -i * panelSize },
                            delay: 1000 * dist
                        });
                    }
                }
            }
        }
    }

    return (
    <Entity id="Background" {...props}>
        <Box // Floor
            position={{ x: 0, y: -2, z: 0 }}
            width={ 30 }
            depth={ 30 }
            height={ 0.1 }
            color="#000"
        />
        { panels.map((val, idx) => {
            return <Entity className="panel">
                    <Box moveup={{
                        delay: val.delay
                    }}
                    material={ panelMaterial }
                    height={ panelHeight }
                    width={ panelSize }
                    depth={ panelSize }

                    position={ val.position }
                    />
                </Entity>
        }) }
        <Light 
        type="point"
        distance={ 30 } 
        color="#80bfff"
        intensity={ 0.4 } 
        position={{ x: 0, y: -1.5, z: 0 }}/>
    </Entity>)
}

export const ThreeDView = (props) => {
    const { toggle3D } = useContext(MyContext);

    useEffect(() => {
        const THREE = window.THREE;

        if (!("exitvr" in AFRAME.components)) {
            AFRAME.registerComponent("exitvr", {
                init: function () {
                    this.el.addEventListener('click', function (evt) {
                        //console.log("exiting vr");
                        document.querySelector('a-scene').exitVR();
                        toggle3D();
                    });
                }
            })
        }

        if (!("button-hover" in AFRAME.components)) {
            AFRAME.registerComponent("button-hover", {
                schema: {
                    to: {type: 'vec3'}
                },
            
                init: function () {
                    var data = this.data;
                    var el = this.el;
                    this.el.addEventListener('mouseenter', function () {
                        el.object3D.scale.copy(data.to);
                    });
                    this.el.addEventListener('mouseleave', function () {
                        el.object3D.scale.copy({x: 1, y: 1, z: 1});
                    });
                }
            })
        }

        if (!("glass" in AFRAME.shaders)) {
            AFRAME.registerShader("glass", {
                schema: {
                    color: { type: "color", is:'uniform', default: "white" },
                    opacity: { type: "number", is:'uniform', default: 0.1 }
                },
                raw: false,
                fragmentShader:
                `
                  // Use medium precision.
                  precision mediump float;
                
                  // This receives the color value from the schema, which becomes a vec3 in the shader.
                  uniform vec3 color;
                
                  // This receives the opacity value from the schema, which becomes a number.
                  uniform float opacity;
                
                  // This is the shader program.
                  // A fragment shader can set the color via gl_FragColor,
                  // or decline to draw anything via discard.
                  void main () {
                    // Note that this shader doesn't use texture coordinates.
                    // Set the RGB portion to our color,
                    // and the alpha portion to our opacity.
                    gl_FragColor = vec4(color, opacity);
                  }
                `
            })
        }

        if (!("pointer" in AFRAME.components)) {
            AFRAME.registerComponent("pointer", {
                init: () => {
                    this.el.addEventListener("pointingstart", () => {
                        this.el.setAttribute("mixin", "laser-hands");
                    });
                    this.el.addEventListener("pointingend", () => {
                        this.el.setAttribute("mixin", "physics-hands");
                    });
                    this.el.addEventListener("thumbstickmoved", (e) => {
                        const force = new AMMO.btVector3(e.detail.x, 0, e.detail.y);
                        const pos = new AMMO.btVector3(this.el.object3D.position.x, this.el.object3D.position.y, this.el.object3D.position.z);
                        this.el.body.applyForce(force, pos);
                        AMMO.destroy(force);
                        AMMO.destroy(pos);
                    })
                }
            })
        }

        if (!("moveup" in AFRAME.components)) {
            AFRAME.registerComponent("moveup", {
                schema: {
                    delay: { default: 0 },
                },
                init: function () {
                    this.el.setAttribute("animation__move", { 
                        property: "object3D.position.y", 
                        dir: "alternate", 
                        from: this.el.object3D.position.y , 
                        to: this.el.object3D.position.y + 0.3, 
                        dur: 800, 
                        easing: "easeInOutSine", 
                        loop: 1, 
                        delay: 0,
                        startEvents: "startmove" 
                    });
                    const e = this.el;
                    setTimeout(() => { e.emit("startmove", null, false); }, this.data.delay)
                    e.addEventListener("animationcomplete__move", (ev) => {
                        setTimeout(() => { e.emit("startmove", null, false); }, 6000)
                    })
                }
            })
        }
    }, [])


    const gridPos = (idx) => {
        const gridWidth = 0.6;
        const gridHeight = 0.6;
        const margin = 0.1;
        const rowCount = 7;
    
        const col = Math.floor(idx / rowCount);
        const row = idx % rowCount;

        const pos = { x: -3.9, y: 1.6, z: 2.6 };
    
        return { x: pos.x + (col * (gridHeight + margin) + (gridWidth / 2)), y: pos.y, z: pos.z + ((-1) * (row * (gridWidth + margin) + (gridHeight / 2))) };
    }

    const models = [
        {
            model: "MMD_UI_Button",
            scale: 0.008
        },
        {
            model: "Clipper",
            scale: 0.004
        },
        {
            model: "Bowl",
            scale: 0.007
        },
        {
            model: "Vape_Holder",
            scale: 0.006
        },
        {
            model: "crimp_5mm",
            scale: 0.005
        },
        {
            model: "gun",
            scale: 0.003
        }];

    return (
        <div style={{ position: "absolute", height: "100%", width: "100%"}}>
            <Scene vrModeUI={{ enabled: true }} physics=" driver: ammo; debug: false; debugDrawMode: 0;" reflection shadow={{ enabled: true, type: 'pcfsoft'}}>
                <Entity cursor="rayOrigin: mouse" />
                <Assets>
                    {models.map((val, idx) => {
                        return <AssetItem key={ idx } id={ "model_" + val.model } src={ "models/" + val.model + ".obj"} />
                    })}
                    <Mixin id="all-interactions"
                        hoverable grabbable stretchable draggable
                        event-set__hoveron="_event: hover-start; material.opacity: 0.7; transparent: true"
                        event-set__hoveroff="_event: hover-end; material.opacity: 1; transparent: false"
                        dynamic-body
                    />
                    <Mixin id="physics-hands"
                        physics-collider
                        ammo-body="type: static" 
                        ammo-shape="type: sphere; fit: manual; sphereRadius: 0.02"
                        super-hands="colliderEvent: collisions;
                            colliderEventProperty: els;
                            colliderEndEvent: collisions;
                            colliderEndEventProperty: clearedEls;"
                    />
                    <Mixin id="laser-hands"
                        physics-collider
                        super-hands="colliderEvent: raycaster-intersection;
                            colliderEventProperty: els;
                            colliderEndEvent: raycaster-intersection-cleared;
                            colliderEndEventProperty: clearedEls"
                    />
                </Assets>
                <Entity>
                    <Camera wasdControls={{ acceleration: 30 }} /> 
                </Entity>
                <Entity handControls={{ hand: "left", handModelStyle: "highPoly" }} mixin="physics-hands" pointer />
                <Entity handControls={{ hand: "right", handModelStyle: "highPoly" }} mixin="physics-hands" pointer />

                <Light type="point" color="#DDD" intensity="0.2" position={{ x: 0, y: 3, z: 0 }} />

                <Entity id="Guardian" 
                    static-body>
                    <Box
                    position={{ x: 0, y: guardianHeight / 2, z: (guardianSize + guardianDepth) / 2 }}
                    height={ guardianHeight }
                    depth={ guardianDepth }
                    width={ guardianSize + (2 * guardianDepth) }
                    color={ guardianColor }
                    ammo-body="type: static" 
                    ammo-shape="type: box"
                    />
                    <Box 
                    material={{ shader: "glass" }}
                    position={{ x: 0, y: (wallHeight + guardianHeight) / 2, z: (guardianSize + guardianDepth) / 2 }}
                    height={ wallHeight - guardianHeight}
                    depth={ guardianDepth }
                    width={ guardianSize }
                    ammo-body="type: static" 
                    ammo-shape="type: box"
                    />
                    <Box
                    position={{ x: 0, y: guardianHeight / 2, z: (-1) * (guardianSize + guardianDepth) / 2 }}
                    height={ guardianHeight }
                    depth={ guardianDepth }
                    width={ guardianSize + (2 * guardianDepth) }
                    color={ guardianColor }
                    ammo-body="type: static" 
                    ammo-shape="type: box"
                    />
                    <Box 
                    material={{ shader: "glass" }}
                    position={{ x: 0, y: (wallHeight + guardianHeight) / 2, z: (-1) * (guardianSize + guardianDepth) / 2 }}
                    height={ wallHeight - guardianHeight}
                    depth={ guardianDepth }
                    width={ guardianSize }
                    ammo-body="type: static" 
                    ammo-shape="type: box"
                    />
                    <Box
                    position={{ x: (guardianSize + guardianDepth) / 2, y: guardianHeight / 2, z: 0 }}
                    rotation={{ x: 0, y: 90, z: 0 }}
                    height={ guardianHeight }
                    depth={ guardianDepth }
                    width={ guardianSize }
                    color={ guardianColor }
                    ammo-body="type: static" 
                    ammo-shape="type: box"
                    />
                    <Box 
                    material={{ shader: "glass" }}
                    position={{ x: (guardianSize + guardianDepth) / 2, y: (wallHeight + guardianHeight) / 2, z: 0 }}
                    rotation={{ x: 0, y: 90, z: 0 }}
                    height={ wallHeight - guardianHeight}
                    depth={ guardianDepth }
                    width={ guardianSize }
                    ammo-body="type: static" 
                    ammo-shape="type: box"
                    />
                    <Box
                    position={{ x: (-1) * (guardianSize + guardianDepth) / 2, y: guardianHeight / 2, z: 0 }}
                    rotation={{ x: 0, y: 90, z: 0 }}
                    height={ guardianHeight }
                    depth={ guardianDepth }
                    width={ guardianSize }
                    color={ guardianColor }
                    ammo-body="type: static" 
                    ammo-shape="type: box"
                    />
                    <Box 
                    material={{ shader: "glass" }}
                    rotation={{ x: 0, y: 90, z: 0 }}
                    position={{ x: (-1) * (guardianSize + guardianDepth) / 2, y: (wallHeight + guardianHeight) / 2, z: 0 }}
                    height={ wallHeight - guardianHeight}
                    depth={ guardianDepth }
                    width={ guardianSize }
                    ammo-body="type: static" 
                    ammo-shape="type: box"
                    />
                
                    <Box // Roof
                    position={{ x: 0, y: wallHeight + 0.05, z: 0 }}
                    width={ guardianSize + (2 * guardianDepth) }
                    depth={ guardianSize + (2 * guardianDepth) }
                    height={ 0.1 }
                    color="#444441" 
                    ammo-body="type: static" 
                    ammo-shape="type: box"
                    />
                
                    <Box // Floor
                    position={{ x: 0, y: 0, z: 0 }}
                    width={ guardianSize }
                    depth={ guardianSize }
                    height={ 0.1 }
                    color="#444441" 
                    ammo-body="type: static" 
                    ammo-shape="type: box"
                    />
                </Entity>
                
                <Background />

                <Entity id="Board">
                    <MyObject
                    position={{ x: 0, y: 0, z: -3.5 }}
                    ammo-body="type: static" 
                    ammo-shape="type: box"
                    />
                    <RoundedPlane 
                    position={{ x: 0, y: 2, z: -3.5 }}
                    rotation={{ x: 0, y: 0, z: 0 }}
                    color="#666"
                    width={ 2 }
                    height={ 2 }
                    ammo-body="type: static" 
                    ammo-shape="type: box"
                    >
                        <Text
                            value="Welcome"
                            position={{ x: 0, y: 0.8, z: 0 }}
                            align="center"
                            color={ textColor }
                            scale={ headerScale }
                        />
                        <Text
                            value="This version is still a work in progress.\n\n\nYou can find some of my 3D \nmodels on the table to the left.\n\nSettings are to your right.\n\nClick on the button behind you to \nleave VR."
                            position={{ x: 0, y: -0.2, z: 0 }}
                            material="color: #CCC"
                            align="center"
                            color={ textColor }
                            scale={ textScale }
                        />
                    </RoundedPlane>
                </Entity>

                <Entity id="Table">
                    <Box
                    position={{ x: -3.8, y: 0.4, z: -2.5 }}
                    height={ 0.8 }
                    width= { 0.2 }
                    depth= { 0.2 } 
                    material={ tableMaterial }
                    ammo-body="type: static" 
                    ammo-shape="type: box"
                    />
                    <Box
                    position={{ x: -3.8, y: 0.4, z: 2.5 }}
                    height={ 0.8 }
                    width= { 0.2 }
                    depth= { 0.2 } 
                    material={ tableMaterial }
                    ammo-body="type: static" 
                    ammo-shape="type: box"
                    />
                    <Box
                    position={{ x: -2.7, y: 0.4, z: -2.5 }}
                    height={ 0.8 }
                    width= { 0.2 }
                    depth= { 0.2 } 
                    material={ tableMaterial }
                    ammo-body="type: static" 
                    ammo-shape="type: box"
                    />
                    <Box
                    position={{ x: -2.7, y: 0.4, z: 2.5 }}
                    height={ 0.8 }
                    width= { 0.2 }
                    depth= { 0.2 } 
                    material={ tableMaterial }
                    ammo-body="type: static" 
                    ammo-shape="type: box"
                    />
                    <Box // top
                    static-body 
                    position={{ x: -3.35, y: 0.75, z: 0 }}
                    height={ 0.02 }
                    width= { 1 }
                    depth= { 5 } 
                    material={ tableMaterial } 
                    ammo-body="type: static" 
                    ammo-shape="type: box"
                    />
                </Entity>
                <Entity
                id="Grid">
                    <Box 
                    width={ 0.3 }
                    height={ 0.3 }
                    depth={ 0.3 }
                    ammo-body="type: dynamic" 
                    ammo-shape="type: box"
                    material={ objectMaterial }
                    position={ gridPos(0) }
                    all-interactions
                    />
                    <Sphere 
                    radius={ 0.14 }
                    ammo-body="type: dynamic" 
                    ammo-shape="type: sphere"
                    material={ objectMaterial }
                    position={ gridPos(1) }
                    all-interactions
                    />
                    { models.map((val, idx) => {
                        const s = { x: val.scale || 0.005, y: val.scale || 0.005, z: val.scale || 0.005 };
                        return (
                            <ObjModel 
                            key={ idx } 
                            position={ gridPos(idx + 2) }
                            objModel={{ obj: "#model_" + val.model }} 
                            scale={ s }
                            rotation={{ x: -90, y: 90, z: 0 }}
                            material={ objectMaterial }
                            ammo-body="type: dynamic" 
                            ammo-shape="type: box; fit: manual; halfExtents: 0.05 0.05 0.05" 
                            all-interactions
                            />)
                    })}
                </Entity>

                <Entity id="Settings"
                        position={{ x: 3.95, y: 1.5, z: 0 }} 
                        rotation={{ x: 0, y: 90, z: 0 }}>
                    <RoundedPlane
                        width={ 3 }
                        height={ 1.5 }
                    />
                    <Text 
                        value="Settings"
                        position={{ x: 0, y: 0.5, z: 0 }}
                        rotation={{ x: 0, y: 180, z: 0 }}
                        align="center"
                        color={ textColor }
                        scale={ headerScale }
                    />
                </Entity>

                <Entity id="LeaveButton">
                    <Text 
                    value="Leave 3D"
                    position={{ x: 3.3, y: 0, z: 2.7 }}
                    rotation={{ x: 270, y: 225, z: 0 }}
                    color={ textColor }
                    />
                    <MyObject
                    position={{ x: 3.5, y: 0, z: 3.5 }}
                    exitvr
                    />
                </Entity>
                <Sky color="#000C17" />
            </Scene>
        </div>
        )
}