import axios from "axios"
import { useEffect, useState } from "react";
import { WinWindow } from "../Components";

export const Photography = (props) => {
    return <div className={ props.Included === true ? "" : "normal" } >
        <h1>Photography</h1>
        <p>I have another website dedicated to my photography. You can <a href="https://louis-photography.de" target="_blank" rel="noreferrer">check it out here</a>.</p>
        <p>But here is a small selection of them:</p>
        <div className="gallery">
            <figure className='figure'>
                <img src="https://i1.wp.com/louis-photography.de/wp-content/uploads/2019/10/PIC_9671.jpg?fit=1920%2C1272&ssl=1" alt="Example 1" />
            </figure>
            <figure className='figure'>
                <img src="https://i2.wp.com/louis-photography.de/wp-content/uploads/2019/10/PIC_2395.jpg?fit=1920%2C1272&ssl=1" alt="Example 2" />
            </figure>
            <figure className='figure'>
                <img src="https://i0.wp.com/louis-photography.de/wp-content/uploads/2019/10/PIC_5840.jpg?fit=1920%2C1272&ssl=1" alt="Example 3" />
            </figure>
        </div>
    </div>
}

export const ThreeD = (props) => {
    const t = "?access_token=b895ab8601bffca8e85e09be7f189339";

    const [data, setData] = useState([]);
    const [details, setDetails] = useState({});

    const loadDetails = (url) => {
        axios(url + t)
        .then((r) => {
            let tmp = details;
            tmp[r.data.id] = r.data;
            setDetails(tmp);
        })
        .catch(e => {
            console.error(e);
        })
    }

    useEffect(() => {
        axios("https://api.thingiverse.com/users/k3i/things" + t)
        .then((resp) => {
            if (resp.data) {
                setData(resp.data);
            }
        })
        .catch((err) => {
            setData(err.message);
        });
    });

    return <div className={ props.Included === true ? "" : "normal" } >
        <h1>3D Design</h1>
        <p>I've always had a fascination for three dimensional objects designed on computers. After studying CAD in a course at University, it's been a hobby of mine to design and print things. The designs are usually made in <a href="https://www.plm.automation.siemens.com/global/en/products/nx/" rel="noreferrer" target="_blank">Siemens NX 12.0</a>, sometimes using <a href="https://www.blender.org/" rel="noreferrer" target="_blank">Blender</a>.</p>
        <p>The objects I design in 3D can mostly be found on <a target="_blank" href="https://www.thingiverse.com/k3i/designs" rel="noreferrer">Thingiverse</a>, since I mostly create them for 3D printing.</p>
        { data.map((r, idx) => {
                    loadDetails(r.url);
                    const c = <>
                        <div style={{float: "left", margin: "0 1em 0 0"}}>
                            <img src={r.preview_image} style={{height: "8em", width: "auto"}} alt={r.name} />
                        </div>
                        <p style={{ minHeight: "7em" }}>{ details[r.id]? details[r.id].description : null }</p>
                        <p>
                            <a target="_blank" rel="noreferrer" href={r.public_url}>View on Thingiverse</a>
                        </p>
                    </>
                    return <WinWindow key={idx} Title={ r.name } Minimized={false}>{ c }</WinWindow>
                }) }
    </div>
}

export const Design = () => {
    return <div className="normal">
    <h1>Design</h1>
    <p>I am definitely not a designer and would prefer just developing designs that are provided for me. But that doesn't mean that it was never necessary for me to design something. In fact, most of my <a href="/software/front-end">Front-End projects</a> are my own design (click the link to see them on the software page).</p>
    <p>On this page I decided to show you my 3D work and a small view into my photography. I know it doesn't technically belong under "design" but I couldn't come up with a better word for this category yet. </p>
    <ThreeD Included />
    <br />
    <Photography Included />
    </div>
}