import axios from "axios"
import { useContext } from "react";
import { useEffect, useState } from "react";
import { Rating, WinWindow } from "../Components";
import { MyContext } from "../MyContext";

const divExp = (val, idx) => {
    if (val.length >= 2) {
        return  <div key={idx} className="tr">
        <div className="td">
            { val[0] }
        </div>
        <div className="td">
            <Rating Rating={val[1]}/>
        </div>
        </div>
    }
    else {
        return null;
    }
}

const Experience = (props) => {
    const f = "ShowFront" in props ? props.ShowFront : true;
    const b = "ShowBack" in props ? props.ShowBack : true;
    const a = "ShowApp" in props ? props.ShowApp : true;

    const front = [["HTML5", 5], ["CSS", 4], ["JavaScript", 5], ["React.js", 5]];
    const back =  [["PHP", 5], ["NodeJS", 5], ["Java", 3], ["(My)SQL", 3]];
    const app = [["C", 3], ["C++", 2], ["C#", 5], ["Python", 4]];
    return <div className="table">
            <div className="tr">
                {f && <div className="td">
                    <h2><a href="#front-end">Front End</a></h2>
                        {front.map((x, idx) => {
                            return divExp(x, idx)
                        })}
                </div> }
                {b && <div className="td">
                    <h2>Back End</h2>
                        {back.map((x, idx) => {
                            return divExp(x, idx)
                        })}
                </div>}
                {a && <div className="td">
                    <h2 style={{opacity: "0"}}>_</h2>
                        {app.map((x, idx) => {
                            return divExp(x, idx)
                        })}
                </div>}
            </div>
            <br />
        </div>
}

export const Coding = (props) => {
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState([]);
    const [display, setDisplay] = useState(<></>);

    useEffect(() => {
        axios("https://api.github.com/users/louis-dresbach/repos")
        .then((resp) => {
            if (resp.data) {
                let tmp = resp.data.filter(r => (!r.fork && r.name !== "louis-dresbach"));
                setData(tmp);
            }
        })
        .catch((err) => {
            setData(err.message);
        });
    }, []);

    useEffect(() => {
        console.log("updating");
        const tmp = data.map((r, idx) => {
            if (filter.length <= 0 || filter.includes(r.language)) {
                const ret = <WinWindow key={ idx } Title={ r.name }>
                    <small style={{ float: "right" }}>{ r.language }</small>
                    <br />
                    <p>{ r.description }</p>
                    <a href={ r.html_url } target="_blank" rel="noreferrer">View on Github</a>
                </WinWindow>;
                return ret;
            }
            else 
                return null;
        });
        setDisplay(tmp);
    }, [filter, data])

    const filters = () => {
        let ret = ["All"];

        data.forEach((d) => {
            if (d.language !== null && d.language !== "" && !ret.includes(d.language))
                ret.push(d.language);
        });

        const add = (l) => {
            if (!filter.includes(l)) {
                let tmp = [...filter];
                tmp.push(l);
                setFilter(tmp);
            }
        };

        const rem = (l) => {
            if (filter.includes(l)) {
                let tmp = [...filter].filter(item => item !== l);
                setFilter(tmp);
            }
        };

        const hid = { opacity: "0.5" };

        return <div>
            <p>Filter by language: </p>
            {ret.map((r, idx) => {
                if (r === "All") {
                    return <button key={ idx } style={ (filter.length > 0) ? hid : null } onClick={ () => { filter.length > 0 && setFilter([]) }}>{ r }</button>;
                }
                else {
                    return <button key={ idx } style={ (filter.includes(r)) ? null : hid } onClick={ () => { filter.includes(r) ? rem(r) : add(r) }}>{ r }</button>;
                }
            })}
            <br />
            <br />
        </div>
    }
    
    return <div className={ props.Included === true ? "" : "normal" } >
        <h1>Software Experience</h1>
        <p>I started coding early on in highschool, where I mostly developed mods and simple hacks for some games I was playing. That started me on programming in C, C++ and C#. Then I began to run my own custom game-servers using my modified version of the code.</p>
        <Experience />
        <div>
            <h2>My public Github repositories</h2>
            { filters() }
            { display }
            <br />
        </div>
        <FrontEnd Included />
        <BackEnd Included />
    </div>
}
class Site {
    constructor(name, url, description) {
        this.Name = name;
        this.URL = url;
        this.Description = description;
    }
}

export const FrontEnd = (props) => {
    const sites = [ 
        new Site("Portfolio", "https://louis-dresbach.de/", "This is my main portfolio, where I try to keep an overview of my projects. It is coded in pure ReactJS."),
        new Site("Photography Page", "https://louis-photography.de/", 'My photography portfolio and store.'), 
        new Site("DunstKunst", "https://dunstkunst.de/", 'A small online store for Shisha-related items. Still under construction.'), 
        new Site("Green Watering", "https://green-watering.com/", 'Website dedicated to my ongoing project for automatic plant irrigation.'), 
        new Site("Nano Faucet", "https://nanofaucet.online/", 'A simple faucet for the crypto-currency <a href="https://nano.org/" target="_blank" rel="noreferrer">Nano</a>, supported by a Nano Node running on the same server. Coded in a combination of ReactJS and Laravel (PHP) for the website itself, and a NodeJS server for interactions between the site and the node.'), 
        new Site("Banano Faucet" , "https://bananofaucet.online/", 'Essentially just a slight variation of the nano faucet with only minor adjustments to fit <a href="https://banano.cc/" target="_blank" rel="noreferrer">Banano</a>.'), 
        new Site("KExchange", "https://kexchange.online/", 'A small and simple exchange website for crypto currencies like XNO, BAN.'), 
    ]
    return <div className={ props.Included === true ? "" : "normal" } >
        <h1 id="front-end">Front End</h1>
        <p>Here is a selection of websites that I developed or am currently working on. </p>
        {sites.map((s, idx) => {
            const u = /https:\/\/([^/]*)\//g.exec(s.URL)[1];
            const p = "/images/" + u + "__1024x768.png";
            return <WinWindow 
            key={ idx }
            Title={ s.Name }
            >
            <p>
                <a href={ s.URL }>{ u }</a>
            </p>
            <p dangerouslySetInnerHTML={{ __html: s.Description }} />
            <img alt={ s.Name + " screenshot" } src={ p } style={{width: "100%"}} />
            </WinWindow>
        })}
        <br />
    </div>
}

export const BackEnd = (props) => {
    const { lightTheme } = useContext(MyContext);
    return <div className={ props.Included === true ? "" : "normal" } >
        <h1 id="back-end">Back End</h1>
        <p>Visualizing back-end software isn't as straightforward as the front-end. So to help showcase some work, I have decided to create diagrams as well as a short description for some of the projects I have created.</p>
        <p>The diagrams are simplified alot just to show the interaction between the different nodes. It is not supposed to be an in-depth view of the whole project.</p>
        <br />
        <WinWindow Title="Crypto Exchange">
            <p>The small crypto exchange is made up by the following components:
                <ul>
                    <li>ReactJS Frontend</li>
                    <li>LaravelPHP Backend</li>
                    <li>MySQL for the database</li>
                    <li>NodeJS server for websockets</li>
                    <li>Crypto nodes for each supported currency</li>
                </ul>
            </p>
            <img src={ "/images/Exchange Diagram" + (lightTheme ? " Light" : "") + ".png" } alt="Sequence Diagram for Crypto Exchange" />
        </WinWindow>
        <WinWindow Title="Series AutoSkipper">
            <p>This is a small browser extension that allows you to skip annoying intros, outros, recaps and previews like a lot of the big streaming platforms.</p>
            <p>It is made up by the following components:
                <ul>
                    <li>JavaScript for the extension itself</li>
                    <li>NodeJS server for websockets (to support groupwatches)</li>
                    <li>Simple PHP backend to load new timestamps once available</li>
                    <li>Small Python scripts that automatically parse different websites to create the database</li>
                </ul>
            </p>
            <img src={ "/images/AutoSkipper Diagram" + (lightTheme ? " Light" : "") + ".png" } alt="Sequence Diagram for AutoSkipper extension" />
        </WinWindow>
    </div>
}